.footer {
  font-size: 0.9rem;
  padding: 1em 0;
  margin-top: 4em;
  text-align: center;
}

@media (max-width: 600px) {
  .footer {
    padding: 1em 0;
    margin-top: 3em;
  }
}
